import React, {useEffect} from 'react';
import useAppleDevice from "../hooks/isAppleDevice";
import {appGooglePlayLink, appAppleLink} from '../helpers'
import {useRouter} from 'next/router';

export default function App() {
	const isAppleDevice = useAppleDevice();
	const router = useRouter();

	useEffect(() => {
		if(isAppleDevice === null) return;
		if(isAppleDevice) {
			router.push(appAppleLink);
		} else {
			router.push(appGooglePlayLink);
		}
	}, [router, isAppleDevice])
}
